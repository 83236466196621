img {
  max-width: 100%;
}

#commentfilters {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

#commentfilters li {
  display: inline-block;
  padding-right: 20px;
}

.slimcol {
  width: 20px;
}

/* .form-group {
  margin-bottom: 5px;
} */

.form-control {
  /*padding: 4px 8px;
    font-size: 13px;
    height:auto;*/
  height: 36px;
  border: 2px solid #f3f4f5;
}

.input-group-addon {
  border: 2px solid #f3f4f5;
}

.form-horizontal .control-label {
  padding-top: 0px;
}

.mt5 {
  margin-top: 5px;
}

.after-amount {
  display: block;
  width: 100%;
  text-align: right;
}

#loginform .input-group-icon .input-group-addon span.icon.icon-lg,
.input-search .input-group-addon span.icon.icon-lg {
  padding: 0px 14px;
  font-size: 18px;
}

.center-sign h2 {
  letter-spacing: 0px;
}

.center-sign ul {
  list-style-type: none;
  margin: 0px;
  padding: 0 0 20px 0;
}

.modal-block .panel {
  background-color: #fff;
}

.modal-header h5 {
  color: #fff;
}

/* ------ Forms ----*/

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

select.form-control {
  border: 2px solid #f1f1f1;
}

.cvlabel {
  text-align: right;
  @media screen and (max-width: 767px) {
    text-align: left;
    display: block;
    margin-bottom: 5px;
    transform: translateY(0px) !important;
  }
}

header.header {
  @media screen and (max-width: 767px) {
    width: 100vw;
    padding: 15px 0px 0px 20px;
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.content-body {
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}

.header .logo-container {
  @media screen and (max-width: 767px) {
    height: 60px;
    position: relative;
    z-index: 0;
  }
}

#sidebar-left {
  @media screen and (max-width: 767px) {
    overflow: visible;
  }
}

#sidebar-left .sidebar-header {
  @media screen and (max-width: 767px) {
    position: fixed;
    top: 0px;
    right: 0px;
    /*color: #777;
  height: 50px;*/
    .sidebar-title {
      display: none;
    }
  }
}

#userbox.userbox {
  @media screen and (max-width: 767px) {
    float: none;
    margin: 0 0 30px 25px;
  }
}

#userbox.userbox:after {
  @media screen and (max-width: 767px) {
    background: none;
  }
}

.nav.nav-main {
  @media screen and (max-width: 767px) {
    display: block;
    li {
      float: none;
      display: block;
    }
  }
}

.modal.show .modal-dialog {
  margin-bottom:150px;
}