$grid-breakpoints: (
  xs: 0,
  sm: 567px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 2200px
);

$container-max-widths: (
  sm: 567px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 2200px
);