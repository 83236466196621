@import "./vars";
@import "./animate";
@import "customize-bootstrap.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";
h1,
h2,
h3,
h4,
h5 {
    font-weight: 700 !important;
    font-style: normal !important;
    text-transform: none !important;
    text-decoration: none !important;
    color: $color-dark-grey;
}

h1 {
    font-family: "Quicksand", sans-serif !important;
    font-size: 50px !important;
    margin-bottom: 57px !important;
}

h2,
h3,
h4 {
    font-family: "Quicksand", sans-serif;
}

h5 {
    font-family: "Poppins", sans-serif;
    margin-bottom: 42px;
}

.modal-header h5 {
    margin-bottom: 0px;
}

h6 {
    color: $blue !important;
}

p,
li,
.control-label {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 27px;
}

input,
textarea,
select {
    font-family: "Poppins", sans-serif !important;
}

.tab-content {
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
    background-color: #fff;
    border: 1px solid #eee;
    border-top: 0;
    padding: 15px;
}

#kompetencer,
#stamdata,
#extradatapanel,
#konsulentdata,
#overview,
#erfaring {
    font-family: "Poppins", sans-serif;

    h4 {
        font-weight: 400;
        color: #999;
    }
    h5 {
        margin-bottom: 5px;
        font-weight: 400;
        font-size: 16px;
    }
}
#kompetencer,
#stamdata,
#extradatapanel,
#konsulentdata,
#erfaring {
    label {
        display: block;
        font-weight: 600;
        line-height: 130%;
        transform: translateY(9px);
        &.nospace {
            transform: translateY(4px);
        }
        &:after {
            content: ":";
        }
    }
    textarea,
    select,
    input[type="text"],
    button,
    .quill-wrapper {
        font-size: 14px;
        font-weight: 300;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        /*transition: all 0.4s;*/

        &:hover,
        &:focus {
            transform: translateY(-2px);
            -webkit-box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.29);
            -moz-box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.29);
            box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.29);
        }

        &:disabled {
            &:hover,
            &:focus {
                transform: none;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
            }
        }
    }

    .checkbox {
        label {
            input {
                margin-right: 7px;
            }

            &:after {
                content: "";
            }
        }
    }

    input[type="text"] {
        height: 35px;
        color: #272e46;
        line-height: 1.3;
        padding: 0.6em 0em 0.5em 0.5em;
        border: 2px solid #f1f1f1;
        border-radius: 4px;
        background-color: #fff;
        background-repeat: no-repeat, repeat;
        background-position: left 0.7em top 50%, 0 0;
        background-size: 18px 18px;
        transition: all 0.4s;
        &::placeholder {
            color: #ccc;
        }

        &:focus {
            border-color: #272e46;
        }

        &:hover {
            border-color: #272e46;
        }

        &:disabled {
            border: none;
        }
    }
}

.modal-dialog {
    label {
        padding-top: 4px !important;
        line-height: 130%;
    }

    .modal-header button.close:hover {
        color: white !important;
    }
}

.fileuploadform {
    display: none;
}

label.fileuploadlabel {
    padding: 10px;
    display: table;
    font-weight: 300 !important;
    color: #fff;
    cursor: pointer;
    margin-bottom: 25px;
    &:after {
        content: "" !important;
    }
}

.input-group-addon {
    padding: 8px 12px 4px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-group-addon:first-child {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.clickable {
    cursor: pointer;
}

#editbar {
    width: 100vw;
    padding: 30px;
    background-color: $red;
    position: fixed;
    left: 0px;
    bottom: 0px;
    z-index: 9999;
    animation-delay: 2s;
    text-align: center;

    .btn {
        width: 100%;
    }

    p {
        color: #fff;
        font-size: 16px;
    }
}

.keep-linebreaks {
    white-space: pre-wrap;
}

.pager {
    margin-top: 20px !important;
}

.pagination {
    .page-link {
        &.active {
            font-weight: 600;
            border: none;
            background-color: #fff;
            color: $color-dark-grey;
        }
    }
}

.nav-tabs .nav-item {
    margin-bottom: 0px;
}

.form-group {
    .ng-invalid {
        border-left: 4px solid red;
    }
}

th {
    background-color: $blue !important;
    color: #fff !important;
    padding: 10px !important;
    &:first-child {
        border-top-left-radius: 3.5px;
    }
    &:last-child {
        border-top-right-radius: 3.5px;
    }

    &.sortable {
        padding-right: 17px;
        cursor: pointer;
        white-space: normal;

        .arrows {
            display: inline-block;
            background-image: url("/assets/images/sort.png");
            background-repeat: no-repeat;
            background-position: 0px 0px;
            width: 16px;
            vertical-align: bottom;
            margin-left: 10px;
            height: 18px;
        }

        &.asc {
            .arrows {
                background-image: url("/assets/images/sort_asc.png");
            }
        }
        &.desc {
            .arrows {
                background-image: url("/assets/images/sort_desc.png");
            }
        }
    }
}

/*------------- Search result ------------*/

$blue: #2f3447;

.searchresultwrapper {
    height: 100%;
    padding: 0 0 15px 0;
    .searchresult {
        width: 100%;

        p {
            margin: 0px;
        }

        tr.table-row {
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
                background-color: $blue;
                td {
                    color: #fff;

                    &.relevance {
                        p {
                            color: $blue;
                            background-color: #fff;
                        }
                    }
                }
            }
        }

        tr.skeleton-table-row {
            cursor: default;
            td {
                color: #fff;
            }
        }

        td {
            height: 50px;
            padding: 5;
            border-bottom: 1px solid #ddd;
            color: $blue;

            &.vurdering {
                padding-left: 10px;
                font-weight: bold;
                i {
                    color: red;
                    margin-right: 5;
                }
            }

            &.consultant {
                width: 50%;
                padding-left: 10px;

                .name {
                    padding-bottom: 3px;
                    font-weight: bold;
                }

                p {
                    line-height: 18px;
                }
            }

            &.locations {
                width: 30%;
                .primary {
                    font-weight: bold;
                }

                p {
                    line-height: 18px;
                }
            }

            &.status {
                width: 20%;
                font-weight: bold;
            }
        }
    }
}

.btn {
    // background-color: $blue;
    // color: #fff;
    // padding: 10px 30px;
    font-weight: normal;
}

/*---Widget specific styles--*/

.widget-header {
    text-align: left;
    padding: 2.142rem 2.142rem 0.5rem 2.142rem;
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
}

.gridster-column.ng-star-inserted {
    border-left: 1px solid #0a19e9 !important;
    border-right: 1px solid #0a19e9 !important;
}

.gridster-row.ng-star-inserted {
    border-top: 1px solid #0a19e9 !important;
    border-bottom: 1px solid #0a19e9 !important;
}

.gridster-column {
    border-left: 1px solid #0a19e9 !important;
    border-right: 1px solid #0a19e9 !important;
}

.gridster-row {
    border-top: 1px solid #0a19e9 !important;
    border-bottom: 1px solid #0a19e9 !important;
}

//Hides scrollbar for firefox, edge and safari
.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

//Hides scrollbar for chrome
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

//Bootstrap tooltip css
ngb-tooltip-window {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: $blue !important;

    .arrow .arrow::before {
        border-left-color: $blue !important;
    }

    .tooltip-inner {
        background-color: $blue !important;
    }
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
    border-left-color: $blue !important;
}
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
    border-right-color: $blue !important;
}

//Custom table with css
$table-header: #1976d2;
$table-header-border: #595d72;
$table-border: #d9d9d9;
$row-bg: #ffff;

.table-container {
    display: block;
    margin: 0 auto;
    width: calc(100% - 28px) !important;
    /* max-width: 1000px; */
    font-family: "Poppins", sans-serif;
}

.flex-table-header {
    position: sticky;
    top: 67px;
    z-index: 2;
    background-color: $blue;
    color: white;
    border-left: solid 1px $table-border;
    box-shadow: 0px 2px 4px -4px rgba(0, 0, 0, 0.74);
    &:first-of-type {
        border-top: solid 1px $table-header-border;
        border-left: solid 1px $table-header-border;
        border-right: solid 1px $table-header-border;
    }

    & > div {
        text-align: left;
    }
}
.flex-table {
    display: flex;
    flex-flow: row wrap;
    border-left: solid 1px $table-border;
    transition: 0.5s;
    margin-bottom: 0px;
    margin-top: 0px;

    &:hover {
        background: lightgrey;
        transition: 100ms;
        /* transform: scale(1.02); */
    }
    &:first-of-type {
        border-top: solid 1px $table-header-border;
        border-left: solid 1px $table-header-border;
    }

    & > div {
        text-align: left;
    }
}

.flex-row {
    width: calc(100% / 3); //The default number of columns
    text-align: center;
    padding: 0.5em 0.5em;
    border-bottom: solid 1px $table-border;
    &:not(:last-child) {
        border-right: solid 1px $table-border;
    }
}

.flex-row-body {
    width: calc(100% / 3); //The default number of columns
    text-align: center;
    padding: 0.5em 0.5em;
    border-bottom: solid 1px $table-border;
    border-right: solid 1px $table-border;
}

.flex-cell {
    height: 6em;
    /* justify-content: center; */
    display: flex;
    align-items: center;
    align-self: center;
}

.fixed-bg {
    background-color: #ccc;
    opacity: 0.5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1030;
}

table {
    th {
        &:first-child,
        &:last-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}

@media all and (max-width: 767px) {
    .flex-row {
        width: calc(100% / 3); //1px = border right
    }

    .column {
        width: 100%;
    }
}

@media all and (max-width: 430px) {
    .flex-table {
        .flex-row {
            border-bottom: 0;
        }
        .flex-row:last-of-type {
            border-bottom: solid 1px $table-border;
        }
    }

    .headerr {
        .flex-row {
            border-bottom: solid 1px;
        }
    }

    .flex-row {
        width: 100%; //1px = border right

        &.first {
            width: 100%;
            border-bottom: solid 1px $table-border;
        }
    }

    .column {
        width: 100%;
        .flex-row {
            border-bottom: solid 1px $table-border;
        }
    }

    .flex-cell {
        width: 100%; //1px = border right
    }
}

app-todo-list {
    height: 100%;
}

.quill-wrapper {
    .ql-container .ql-snow {
        height: auto;
    }
    .ql-toolbar {
        border-radius: 3px 3px 0 0;
        border: 1px solid #ced4da;

        button:hover {
            transform: unset !important;
            box-shadow: unset !important;
        }
    }

    .ql-editor {
        height: 180px;
        overflow-y: scroll;
        resize: vertical;
        p {
            line-height: 20px;
            color: #495057;
        }
    }
}
.underline-action {
    color: $blue !important;
    display: inline;
    padding-right: 8px;

    &:hover,
    & i:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.faded {
    opacity: 50%;
}

.language-picker-area {
    position: absolute;
    right: 85px;
    top: 14px;

    display: block;
    font-size: 13px;
    font-family: sans-serif;
    font-weight: 600;
    color: #272e46;
    line-height: 1.3;
    width: 160px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 2px solid #f1f1f1;
    border-radius: 4px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-repeat: no-repeat, repeat;
    background-position: left 0.7em top 50%, 0 0;
    background-size: 18px 18px;
    transition: all 0.4s;

    &:hover {
        border-color: #272e46;
        transform: translateY(-2px);
        box-shadow: 0px 4px 15px 0px rgb(0 0 0 / 29%);
    }

    .language-picker {
        width: 100%;
    }
}

.new-btn {
    padding: 0.3rem 1rem;
    border-radius: 60px;
    max-width: fit-content;
    min-width: 180px;
    font-weight: bold;
    font-size: 1.1428rem;
    transition: all 0.2s ease;
}
.new-btn-outline {
    background-color: transparent;
    color: $color-dark-grey;
    border: 2px solid $color-dark-grey;

    &:hover {
        background-color: $color-dark-grey;
        color: $color-white;
    }
}

.new-btn-dark {
    background-color: $color-dark-grey;
    color: $color-white;
    border: 2px solid $color-dark-grey;
    &:hover {
        background-color: transparent;
        color: $color-dark-grey;
    }
}

.new-btn-light {
    background-color: transparent;
    color: $color-dark-grey;
    border: 2px solid $color-dark-grey;
    &:hover {
        background-color: $color-dark-grey;
        color: $color-white;
    }
}


quill-editor {
    display: contents !important;
}


/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
