@import "./vars";
@import "./animate";

htlm.dark-mode {
    background-color: $color-dark-grey !important;
    color: $color-white;
}

body,
html {
    background: $color-white;
    width: 100%;
}
html {
    font-size: 14px;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
}
body {
    color: #555;
    font-family: "Open Sans", Arial, sans-serif;
    line-height: 22px;
    margin: 0;
    font-size: 13px;
    overscroll-behavior-y: none;
    // padding-bottom:100px;
}
a {
    color: #ccc;
    text-decoration: none;

}
a:focus,
a:hover {
    color: #d9d9d9;
}
a:active {
    color: #bfbfbf;
}
.body {
    min-height: 100vh;
    width: 100%;
}

.header {
    width: calc(100vw - 92px);
    padding: 0 0px 20px 0;
    background-color: $color-white;
    height: 100px;
    left: 75px !important;
    position: absolute;

    .logo-container {
        padding: 30px 0 0 60px;
    }

    .todo-list {
        position: absolute;
        /* top: -61px; */
        right: 0;
        z-index: 1022;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 1040;

        .todo-button {
            padding: 0;
            border-radius: unset;
            height: 50px;
            width: 73px;
            text-align: center;
        }
    }
}
.inner-wrapper {
    display: table;
    min-height: 100vh;
    padding-top: 60px;
    table-layout: fixed;
    /* overflow: hidden; */
    width: 100%;
}
.content-body {
    display: table-cell;
    padding: 40px;
    position: relative;
    vertical-align: top;
    background-color: $color-light-grey;
}
.content-body > .row + .row {
    padding-top: 10px;
}
.page-header {
    background: #171717;
    border-bottom: none;
    border-left: 1px solid #3a3a3a;
    box-shadow: 1px 3px 0 1px #ccc;
    height: 50px;
    margin: -40px -40px 40px -40px;
    padding: 0;
}
.sidebar-left {
    color: #777;
    display: table-cell;
    position: relative;
    vertical-align: top;
    width: 300px;
    z-index: 1010;
}
@media only screen and (min-width: 768px) {
    .sidebar-left {
        width: 73px;
    }
}
.sidebar-right {
    background: #171717;
    bottom: 0;
    margin-right: -300px;
    min-height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
}
@media only screen and (min-width: 768px) {
    html.sidebar-right-opened .header {
        margin-left: -300px;
        margin-right: 300px;
    }
    html.sidebar-right-opened .inner-wrapper {
        margin-left: -300px;
    }
    html.sidebar-right-opened .sidebar-right {
        margin-right: 0;
    }
}
@media only screen and (min-width: 768px) {
    .content-body,
    .inner-wrapper,
    .sidebar-left {
        display: block;
    }

    .content-body {
        margin-left: 73px;
    }

    .header {
        z-index: 50;
    }
    .inner-wrapper {
        padding-top: 60px;
    }
    .page-header {
        left: 300px;
        margin: 0;
        position: fixed;
        right: 0;
        top: 60px;
    }
}
@media only screen and (max-width: 767px) {
    body,
    html {
        background: $color-white;
    }
    html.mobile-device .sidebar-left,
    html.mobile-device .sidebar-right {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
    body {
        min-height: 100vh;
    }
    .content-body,
    .inner-wrapper,
    .sidebar-left {
        display: block;
    }
    .body {
        min-height: 0;
        overflow: visible;
    }
    .header {
        background: 0 0;
        border: none;
        height: auto;
        position: static;
    }
    .header .logo-container {
        height: 60px;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 99;
    }
    .header .header-right {
        background: $color-white;
        float: none !important;
        height: 60px;
        margin-top: 60px;
        width: 100%;
    }
    .inner-wrapper {
        min-height: 0;
        padding-top: 0;
    }
    .content-body {
        padding: 0 15px 15px;
    }
    .page-header {
        margin: 0 -15px 20px;
    }
    .sidebar-left {
        bottom: 0;
        left: -100%;
        min-height: 0;
        width: auto;
        /*  min-width: 100%;
    min-width: 100vw;
    */
        padding-top: 60px;
        padding-bottom: 50px;
        position: fixed;
        overflow: hidden;
        top: 0;
        z-index: 98 !important;
    }
    .sidebar-right {
        bottom: 0;
        left: auto;
        right: -100%;
        min-height: 0;
        margin-right: 0;
        /*min-width: 100%;
    min-width: 100vw;*/
        top: 0;
        z-index: 100;
    }
    html.no-mobile-device body {
        min-height: 0;
    }
    html.no-mobile-device .body {
        min-height: 100vh;
        overflow: hidden;
    }
    html.no-mobile-device .inner-wrapper {
        overflow-y: auto;
    }
    html.no-mobile-device.sidebar-left-opened,
    html.no-mobile-device.sidebar-left-opened body,
    html.no-mobile-device.sidebar-right-opened,
    html.no-mobile-device.sidebar-right-opened body {
        overflow: hidden;
    }
    html.sidebar-left-collapsed.sidebar-right-opened .sidebar-left {
        margin-left: -300px;
    }
}
@media only screen and (max-width: 767px) {
    .inner-body {
        padding: 40px 15px 0;
    }
}
@media only screen and (min-width: 768px) {
    .inner-menu {
        display: table-cell;
        vertical-align: top;
    }
    .inner-body {
        display: table-cell;
        vertical-align: top;
        padding: 40px;
    }
}
@media only screen and (min-width: 768px) {
    .inner-body,
    .inner-menu {
        display: block;
    }
    .inner-menu-toggle {
        position: absolute;
        top: 114px;
        border-radius: 0 0 5px 0;
        width: 140px;
        z-index: 1002;
    }
    .inner-menu {
        bottom: 0;
        display: block;
        left: 300px;
        position: fixed;
        margin: 0;
        top: 114px;
        width: 300px;
        padding: 35px;
        z-index: 1002;
    }
    .inner-menu-content {
        display: block;
    }
    .inner-body {
        margin-left: 300px;
        border-top: 113px solid transparent;
        margin-top: -110px;
        min-height: 100vh;
        position: relative;
    }
}
@media only screen and (min-width: 768px) {
    html.scroll .inner-body,
    html.scroll .inner-menu {
        display: block;
    }
    html.scroll .inner-menu-toggle {
        position: absolute;
        top: 0;
        border-radius: 0 0 5px 0;
        width: 140px;
        z-index: 3;
    }
    html.scroll .inner-menu {
        display: block;
        position: relative;
        margin: 0;
        width: 300px;
        padding: 35px;
    }
    html.scroll .inner-menu-content {
        display: block;
    }
    html.scroll .inner-body {
        margin-left: 0;
        min-height: 100vh;
        position: relative;
    }
}
@media only screen and (min-width: 768px) {
    html.fixed.sidebar-left-collapsed .inner-menu,
    html.fixed.sidebar-left-collapsed .inner-menu-toggle {
        left: 73px;
    }
    html.fixed.sidebar-left-collapsed.inner-menu-opened .inner-menu-toggle {
        left: 373px;
    }
}
@media only screen and (min-width: 768px) {
    html.fixed.sidebar-right-opened .inner-menu,
    html.fixed.sidebar-right-opened .inner-menu-toggle {
        left: 0;
    }
    html.fixed.sidebar-right-opened.inner-menu-opened .inner-menu-toggle {
        left: -300px;
    }
}
@media only screen and (min-width: 768px) {
    html.fixed.sidebar-left-collapsed.sidebar-right-opened .inner-menu,
    html.fixed.sidebar-left-collapsed.sidebar-right-opened .inner-menu-toggle {
        left: -227px;
    }
    html.fixed.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
        .inner-menu-toggle {
        left: -527px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1365px) {
    .inner-menu,
    html.scroll .inner-menu {
        display: none;
    }
    .inner-menu-toggle,
    html.scroll .inner-menu-toggle {
        display: block;
    }
    .inner-body,
    html.scroll .inner-body {
        margin-left: 0;
    }
    html.fixed.inner-menu-opened .inner-menu,
    html.scroll.inner-menu-opened .inner-menu {
        display: block;
    }
    html.fixed.inner-menu-opened .inner-menu-toggle,
    html.scroll.inner-menu-opened .inner-menu-toggle {
        display: none;
    }
    html.fixed.inner-menu-opened .inner-body,
    html.scroll.inner-menu-opened .inner-body {
        margin-right: -300px;
    }
    html.fixed.inner-menu-opened .inner-body {
        margin-left: 300px;
    }
    html.scroll.inner-menu-opened:before {
        display: block;
    }
}
@media only screen and (min-width: 1366px) {
    .inner-menu,
    html.scroll .inner-menu {
        display: block;
    }
    .inner-menu-toggle,
    html.scroll .inner-menu-toggle {
        display: none;
    }
    .inner-body,
    html.scroll .inner-body {
        margin-right: 0;
    }
    html.fixed.inner-menu-opened .inner-body {
        margin-left: 300px;
    }
    .inner-menu,
    .inner-menu-toggle,
    html.fixed.inner-menu-opened .inner-menu,
    html.fixed.inner-menu-opened .inner-menu-toggle {
        left: 300px;
    }
    html.fixed.sidebar-right-opened .inner-menu,
    html.fixed.sidebar-right-opened .inner-menu-toggle {
        left: 0;
    }
    html.fixed.sidebar-left-collapsed .inner-menu,
    html.fixed.sidebar-left-collapsed .inner-menu-toggle,
    html.fixed.sidebar-left-collapsed.inner-menu-opened .inner-menu,
    html.fixed.sidebar-left-collapsed.inner-menu-opened .inner-menu-toggle,
    html.fixed.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
        .inner-menu,
    html.fixed.sidebar-left-collapsed.sidebar-right-opened.inner-menu-opened
        .inner-menu-toggle {
        left: 73px;
    }
    html.fixed.sidebar-left-collapsed.sidebar-right-opened .inner-menu,
    html.fixed.sidebar-left-collapsed.sidebar-right-opened .inner-menu-toggle {
        left: -227px;
    }
}
.scroll .container,
.container {
    width: 1570px;
}

.panel {
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
}

.panel-body {
    padding: 15px;
}

.panel-title {
    color: #33353f;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    padding: 0;
    text-transform: none;
}

.panel-subtitle {
    color: #808697;
    font-size: 12px;
    line-height: 1.2em;
    margin: 7px 0 0;
    padding: 0;
}

.panel-body {
    /*background: #fdfdfd;*/
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.panel-body-nopadding {
    padding: 0;
}

.panel-heading {
    background: #f6f6f6;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #dadada;
    padding: 18px;
    position: relative;
}

.panel-heading + .panel-body {
    border-radius: 0 0 5px 5px;
}

.panel-footer {
    border-radius: 0 0 5px 5px;
    margin-top: -5px;
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
}

.panel-header {
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
}

.panel-footer-btn-group {
    display: table;
    width: 100%;
    padding: 0;
}

.panel-footer-btn-group a {
    background-color: #f5f5f5;
    display: table-cell;
    width: 1%;
    border-left: 1px solid #ddd;
    padding: 10px 15px;
    text-decoration: none;
}

.panel-footer-btn-group a:hover {
    background-color: #f0f0f0;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1) inset;
}

.panel-footer-btn-group a:first-child {
    border-left: none;
}

.img-circle {
    border-radius: 50%;
}

.tab-content {
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);
    background-color: $color-white;
    border: 1px solid $color-white;
    border-top: 0;
    padding: 15px;
}

.modal-backdrop,
.modal {
    transition: all 0.4s;
}

.modal-backdrop.show {
    opacity: 0.5 !important;
}

.modal.show {
    opacity: 1 !important;
}
.modal-content {
    border-radius: 12px !important;
}

.modal-header {
    background: $blue;
    h2 {
        font-size: 18px;
        color: $color-white;
    }
    .close {
        font-size: 1.5rem;
        font-weight: 700;
        color: $color-white;
        text-shadow: 0 1px 0 $color-white;
        opacity: 1;
    }
    button[aria-label="Close"] {
        &:focus {
            outline: none;
        }
    }
}

/* content - footer inside */
.tab-content .panel-footer {
    margin: -15px;
    margin-top: 15px;
}

.tab-content .panel-header {
    margin: -15px -15px 15px -15px;
}

.m-none {
    margin: 0 !important;
}

.m-auto {
    margin: 0 auto !important;
}

.m-xs {
    margin: 5px !important;
}

.m-sm {
    margin: 10px !important;
}

.m-md {
    margin: 15px !important;
}

.m-lg {
    margin: 20px !important;
}

.m-xl {
    margin: 25px !important;
}

.m-xlg {
    margin: 30px !important;
}

/* spacement top	*/
.mt-none {
    margin-top: 0 !important;
}

.mt-xs {
    margin-top: 5px !important;
}

.mt-sm {
    margin-top: 10px !important;
}

.mt-md {
    margin-top: 15px !important;
}

.mt-lg {
    margin-top: 20px !important;
}

.mt-xl {
    margin-top: 25px !important;
}

.mt-xlg {
    margin-top: 30px !important;
}

/* spacement bottom	*/
.mb-none {
    margin-bottom: 0 !important;
}

.mb-xs {
    margin-bottom: 5px !important;
}

.mb-sm {
    margin-bottom: 10px !important;
}

.mb-md {
    margin-bottom: 15px !important;
}

.mb-lg {
    margin-bottom: 20px !important;
}

.mb-xl {
    margin-bottom: 25px !important;
}

.mb-xlg {
    margin-bottom: 30px !important;
}

/* spacement left	*/
.ml-none {
    margin-left: 0 !important;
}

.ml-xs {
    margin-left: 5px !important;
}

.ml-sm {
    margin-left: 10px !important;
}

.ml-md {
    margin-left: 15px !important;
}

.ml-lg {
    margin-left: 20px !important;
}

.ml-xl {
    margin-left: 25px !important;
}

.ml-xlg {
    margin-left: 30px !important;
}

/* spacement right	*/
.mr-none {
    margin-right: 0 !important;
}

.mr-xs {
    margin-right: 5px !important;
}

.mr-sm {
    margin-right: 10px !important;
}

.mr-md {
    margin-right: 15px !important;
}

.mr-lg {
    margin-right: 20px !important;
}

.mr-xl {
    margin-right: 25px !important;
}

.mr-xlg {
    margin-right: 30px !important;
}

/* Spacement Padding */
.p-none {
    padding: 0 !important;
}

.p-xs {
    padding: 5px !important;
}

.p-sm {
    padding: 10px !important;
}

.p-md {
    padding: 15px !important;
}

.p-lg {
    padding: 20px !important;
}

.p-xl {
    padding: 25px !important;
}

.p-xlg {
    padding: 30px !important;
}

/* spacement top	*/
.pt-none {
    padding-top: 0 !important;
}

.pt-xs {
    padding-top: 5px !important;
}

.pt-sm {
    padding-top: 10px !important;
}

.pt-md {
    padding-top: 15px !important;
}

.pt-lg {
    padding-top: 20px !important;
}

.pt-xl {
    padding-top: 25px !important;
}

.pt-xlg {
    padding-top: 30px !important;
}

/* spacement bottom	*/
.pb-none {
    padding-bottom: 0 !important;
}

.pb-xs {
    padding-bottom: 5px !important;
}

.pb-sm {
    padding-bottom: 10px !important;
}

.pb-md {
    padding-bottom: 15px !important;
}

.pb-lg {
    padding-bottom: 20px !important;
}

.pb-xl {
    padding-bottom: 25px !important;
}

.pb-xlg {
    padding-bottom: 30px !important;
}

/* spacement left	*/
.pl-none {
    padding-left: 0 !important;
}

.pl-xs {
    padding-left: 5px !important;
}

.pl-sm {
    padding-left: 10px !important;
}

.pl-md {
    padding-left: 15px !important;
}

.pl-lg {
    padding-left: 20px !important;
}

.pl-xl {
    padding-left: 25px !important;
}

.pl-xlg {
    padding-left: 30px !important;
}

/* spacement right	*/
.pr-none {
    padding-right: 0 !important;
}

.pr-xs {
    padding-right: 5px !important;
}

.pr-sm {
    padding-right: 10px !important;
}

.pr-md {
    padding-right: 15px !important;
}

.pr-lg {
    padding-right: 20px !important;
}

.pr-xl {
    padding-right: 25px !important;
}

.pr-xlg {
    padding-right: 30px !important;
}
