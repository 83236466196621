$blue: #2f3447;
$bluehover: #6f7487;
$color-primary: #ec4345;
$red: #ec4345;
$actionicon: #666;
$green:#0DA135;


$color-light-terracotta: #F7EDE9;
$color-terracotta: #D8A48F;
$color-light-grey:#EFF0F5;
$color-grey:#7c7f8c;
$color-dark-grey:#2F3446;
$color-black: #000000;
$color-white: #FFFFFF;
$color-grey-dark-mode:#6d717d;


$backgroundHeaderColor:#c3c3c3;
$gridster-stripe-color: #A4FFFF;
$sidebar-color: #ff0000;
$color-default-inverse: #777;
$sidebar-top-header-size: 50px;
$sidebar-background: $blue;
$sidebar-toggle-width: 73px;
$sidebar-toggle-background: #171717;
$sidebar-toggle-bottom-radius: 5px;
$sidebar-toggle-color: #c3c3c3;
$sidebar-toggle-hover-color: #ccc;
$sidebar-border-width: 5px;
$sidebar-border-color: #2f3139;
