$text-color: #33363f;
$border-rad: 6px;
$inner-rad: 4px;

.fokuskonsulenter {
  display: grid;
  margin: 15px;

  grid-template-columns: repeat(1fr);

  @media only screen and (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
    //grid-template-rows: repeat(3, 1fr);
  }
  @media only screen and (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
    //grid-template-rows: repeat(3, 1fr);
  }

  grid-column-gap: 15px;
  grid-row-gap: 15px;
  color: $text-color;

  .fokuskonsulent {
    display: grid;
    cursor: pointer;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 15px;
    line-height: 130%;
    background-color: #fff;
    border: 2px solid #eee;
    border-radius: $border-rad;

    .picture {
      grid-area: 1 / 1 / 3 / 5;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      border-top-left-radius: $inner-rad;
      border-bottom-left-radius: $inner-rad;
    }

    .name {
      grid-area: 1 / 5 / 2 / 13;
      padding: 15px 0 0 10px;
      font-weight: bold;
    }

    .ledig {
      grid-area: 2 / 5 / 3 / 10;
      align-self: end;
      padding: 0 0 15px 10px;
    }

    .star {
      grid-area: 1 / 10 / 3 / 13;
      padding: 15px 15px;
      align-self: end;
      text-align:right;

      span {
        vertical-align: middle;
      }

      i {
        font-size: 120%;
        vertical-align: middle;
        color: red;
        margin-right: 5px;
      }
    }
  }
}

.fokuskonsulent {
  .picture {
    .searchimage {
      border-radius: 0px;
      width:100%;
      height:100%;
      background-position: center center;
    }
  }
}

.topTenConsultant {
  .picture {
    .searchimage {
      border-radius: 0px;
      width:100%;
      height:100%;
      background-position: center center;
    }
  }
}

