@import "vars.scss";


.btn-link,
a {
  color: #b10b3b;
  cursor: pointer;
}
.btn-link:focus,
.btn-link:hover,
a:focus,
a:hover {
  color: #0099e6;
}
.btn-link:active,
a:active {
  color: #0077b3;
}
.sidebar-left .sidebar-header .sidebar-toggle:hover i {
  color: #b10b3b;
}
@media only screen and (min-width: 768px) {
  html.sidebar-left-collapsed.scroll .sidebar-left .nav-main > li:hover > a span.label {
    background-color: #b10b3b;
  }
}
.userbox.open .dropdown-menu a:hover {
  background: #b10b3b;
}
.open > .dropdown-toggle.btn-primary {
  background: #b10b3b;
  border-color: #0077b3;
}
body .btn-primary.dropdown-toggle {
  border-left-color: #00a3f5;
}
.select2-container--bootstrap .select2-dropdown {
  border-color: #3bf;
}
body .btn-primary {
  color: $color-white!important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: $blue;
  border-color: $blue;
  transition: all 0.3s;
  padding: 6px 30px 6px 30px;
}
body .btn-primary:hover {
  border-color: $bluehover !important;
  background-color: $bluehover;

}
body .btn-primary:active,
body .btn-primary:focus {
  border-color: #0077b3 !important;
  background-color: #0077b3;
}
body .btn-primary[disabled] {
  border-color: #3bf !important;
  background-color: #3bf;
}

body .btn-danger {
  color: $color-white!important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
  padding: 6px 30px 6px 30px;
}

body .btn-success {
  color: $color-white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: $green;
  border-color: $green;
  transition: all 0.3s;
  padding: 6px 30px 6px 30px;
}
body .btn-success:hover {
  border-color: #51b451 !important;
  background-color: #51b451;
}
body .btn-success:active,
body .btn-success:focus {
  border-color: #3f923f !important;
  background-color: #3f923f;
}
body .btn-success[disabled] {
  border-color: #86cb86 !important;
  background-color: #86cb86;
}

body .btn-warning {
  color: $color-white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ed9c28;
  border-color: #ed9c28;
}
body .btn-warning:hover {
  border-color: #efa740 !important;
  background-color: #efa740;
}
body .btn-warning:active,
body .btn-warning:focus {
  border-color: #e89113 !important;
  background-color: #e89113;
}
body .btn-warning[disabled] {
  border-color: #f5c786 !important;
  background-color: #f5c786;
}
body .btn-danger {
  color: $color-white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #d2322d;
  border-color: #d2322d;
}
body .btn-danger:hover {
  border-color: #d64742 !important;
  background-color: #d64742;
}
body .btn-danger:active,
body .btn-danger:focus {
  border-color: #bd2d29 !important;
  background-color: #bd2d29;
}
body .btn-danger[disabled] {
  border-color: #e48481 !important;
  background-color: #e48481;
}
body .btn-info {
  color: $color-white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #5bc0de;
  border-color: #5bc0de;
}
body .btn-info:hover {
  border-color: #70c8e2 !important;
  background-color: #70c8e2;
}
body .btn-info:active,
body .btn-info:focus {
  border-color: #46b8da !important;
  background-color: #46b8da;
}
body .btn-info[disabled] {
  border-color: #b0e1ef !important;
  background-color: #b0e1ef;
}
body .btn-dark {
  color: $color-white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #171717;
  border-color: #171717;
}
body .btn-dark:hover {
  border-color: #242424 !important;
  background-color: #242424;
}
body .btn-dark:active,
body .btn-dark:focus {
  border-color: #0a0a0a !important;
  background-color: #0a0a0a;
}
body .btn-dark[disabled] {
  border-color: #4a4a4a !important;
  background-color: #4a4a4a;
}
.label-primary {
  background: #b10b3b;
}
.text-primary {
  color: #b10b3b !important;
}
.bg-primary {
  background: #b10b3b;
}
.form-control:focus {
  border-color: #3bf;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 136, 204, 0.3);
}
.header .toggle-sidebar-left {
  background: #b10b3b;
}
@media (min-width: 992px) {
  .header.header-nav-menu .header-nav-main nav > ul > li > a.dropdown-toggle:after {
    border-color: #b10b3b transparent transparent transparent;
  }
  .header.header-nav-menu .header-nav-main nav > ul > li > a:focus {
    color: #b10b3b;
  }
  .header.header-nav-menu .header-nav-main nav > ul > li.open > a,
  .header.header-nav-menu .header-nav-main nav > ul > li:hover > a {
    background: #b10b3b;
    color: $color-white;
  }
  .header.header-nav-menu .header-nav-main nav > ul > li.open > a.dropdown-toggle:after,
  .header.header-nav-menu .header-nav-main nav > ul > li:hover > a.dropdown-toggle:after {
    border-color: $color-white transparent transparent transparent;
  }
  .header.header-nav-menu .header-nav-main nav > ul > li.dropdown .dropdown-menu {
    border-top: 5px solid #b10b3b;
  }
}
.page-header h2 {
  border-bottom-color: #b10b3b;
}
.page-header .sidebar-right-toggle:hover {
  color: #b10b3b;
}
html .panel-primary .panel-heading {
  background: #b10b3b;
  border-color: #b10b3b;
}
.panel-heading.bg-primary {
  background: #b10b3b;
}
.panel-body.bg-primary {
  background: #b10b3b;
}
.panel-heading-icon.bg-primary {
  background: #b10b3b;
}
.progress-bar {
  background-color: #b10b3b;
}
.progress .progress-bar-primary {
  background-color: #b10b3b;
}
.toggle label {
  border-left-color: #b10b3b;
  color: #b10b3b;
}
.notifications .notification-menu .notification-title {
  background: #b10b3b;
}
.notifications .notification-menu .notification-title .label-default {
  background-color: #069;
}
.notifications .notification-icon:before,
.notifications .notification-menu:before {
  border-bottom-color: #b10b3b;
}
.checkbox-primary label:before,
html.dark .checkbox-primary label:before {
  background: #b10b3b;
  border-color: #0077b3;
}
.checkbox-text-primary input[type="checkbox"]:checked + label:after,
html.dark .checkbox-text-primary input[type="checkbox"]:checked + label:after {
  color: #b10b3b;
}
.radio-primary input[type="radio"]:checked + label:after,
html.dark .radio-primary input[type="radio"]:checked + label:after {
  background: #b10b3b;
  -webkit-box-shadow: 0 0 1px #b10b3b;
  box-shadow: 0 0 1px #b10b3b;
}
.progress-bar {
  background-color: #b10b3b;
}
.progress .progress-bar-primary {
  background-color: #b10b3b;
}
